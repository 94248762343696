import React, { useState } from "react";
import "../Toggler/Toggler.scss";
import { ImPinterest } from "@react-icons/all-files/im/ImPinterest";
import { FaFacebook } from "@react-icons/all-files/fa/FaFacebook";
import { FaMediumM } from "@react-icons/all-files/fa/FaMediumM";
import { AiOutlineInstagram } from "@react-icons/all-files/ai/AiOutlineInstagram";
import { AiOutlineClose } from "@react-icons/all-files/ai/AiOutlineClose";
import { GatsbyImage } from "gatsby-plugin-image";
import Logo from "../Logo/Logo";
import { graphql, Link, useStaticQuery } from "gatsby";
import axios from "axios";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import {
  regex,
  caseSuccess,
  caseError,
  caseErrorCheck,
  caseSuccessCheck,
} from "../Validations/SubscriptionForm";
import { RiArrowDropRightLine } from "@react-icons/all-files/ri/RiArrowDropRightLine";

const Toggler = ({ starter, closer }) => {
  const [email, setEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [check, setCheck] = useState(false);
  const [response, setResponse] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [checkResponse, setCheckResponse] = useState("");
  const [accessToken, setAccessToken] = useState('SW8DnHlcXfL0qZ3p3A6JVFCGuZHgbQBY')
  const [refreshToken, setRefreshToken] = useState('6A9C0ye3apjd8TqtIdXNiKaPCqtEuLu9')
  const refreshTokenUrl = `https://auth.aweber.com/oauth2/token?grant_type=refresh_token&refresh_token=${refreshToken}`;


  const handleUser = (e) => {
    setUserName(e.target.value);
  };
  const handleChange = (e) => {
    setEmail(e.target.value);
  };
  const checkInput = (e) => {
    setCheck(e.target.checked);
  };

  function resLoad(setRes, setLoad) {
    setResponse(setRes);
    setIsLoading(setLoad);
  }

  const refreshAccessToken = async () => {
    try {
      const res = await fetch(refreshTokenUrl, {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Basic ' + btoa('FawoOSmefXDLBCV6xcxybCfKb2Fiu2wC:TXyuJq1DjoM1sMkkgpEtv84h71twxLXf'),
        },
        method: "POST",
      })
      const data = await res.json()
      setAccessToken(data.access_token);

      //after refresh token adding subscriber
      // ----------------------------------------------------------------------------------------------------

      await fetch("https://api.aweber.com/1.0/accounts/1958895/lists/6347465/subscribers", {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "User-Agent": "AWeber-Node-code-sample/1.0",
          "Authorization": `Bearer ${data.access_token}`
        },
        method: "POST",
        body: JSON.stringify({
          email: email
        })
      })
        .then(data => {
          if (data.status == 201) {
            caseSuccess("responseMsgToggler");
            resLoad("You have successfully subscribed.", false);
            setEmail('');
            setUserName('')
            setCheck(false)
          }
          else {
            //in case the email already used or unacceptable email
            caseError("responseMsgToggler");
            resLoad("Use Another Email.", false);
          }
        })
        .catch(err => {
          caseError("responseMsgToggler");
          resLoad("Invalid Email.", false);
        })
      // ----------------------------------------------------------------------------------------------------
    } catch (e) {
      console.log(e)
    }
  }

  const Useremail = async () => {
    setIsLoading(true);
    if (email != "" && userName != "" && regex.test(userName) == true) {
      await fetch("https://api.aweber.com/1.0/accounts/1958895/lists/6347465/subscribers", {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "User-Agent": "AWeber-Node-code-sample/1.0",
          "Authorization": `Bearer ${accessToken}`
        },
        method: "POST",
        body: JSON.stringify({
          email: email
        })
      })
        .then(data => {
          if (data.status == 201) {
            caseSuccess("responseMsgToggler");
            resLoad("You have successfully subscribed.", false);
            setEmail('');
            setUserName('')
            setCheck(false)
          }
          else if (data.status == 401) {
            refreshAccessToken();
            //code to refresh access token 
          }
          else {
            caseError("responseMsgToggler");
            resLoad("Use another email!", false);
          }
        })
        .catch(err => {
          caseError("responseMsgMailFooter");
          resLoad('Invalid email address', false);
        })
    } else if (regex.test(userName) == false) {
      caseError("responseMsgToggler");
      resLoad("Invalid name.", false);
    } else {
      caseError("responseMsgToggler");
      resLoad("Please enter all details", false);
    }
  };

  const data = useStaticQuery(graphql`
    {
      allStrapiPost(sort: { order: DESC, fields: date },limit : 3) {
        nodes {
          slug
          date
          Title
          id
          blog_categories {
            category
            slug
          }
          admin_users {
            username
          }
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 720)
              }
            }
          }
          adminImg {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 40)
              }
            }
          }
        }
      }
    }
  `);


  const handleClick = () => {
    closer(!starter);
  };

  if (starter) {
    var clickBody = document.getElementsByClassName("lay");
    clickBody[0].addEventListener("click", function () {
      handleClick();
    });
    const clickFooter = document.getElementsByTagName("Footer");
    clickFooter[0].addEventListener("click", function () {
      handleClick();
    });
    // const clickHeader = document.getElementsByTagName("Header");
    // clickHeader[0].addEventListener("click", function () {
    //   handleClick();
    // });
  }

  return (
    <div className={` ${starter ? "toggle_open active" : "toggle_close"}`}>
      <div className="nav align-items-center">
        <Logo />
        <AiOutlineClose className="close-icon"
          onClick={handleClick}
        />
      </div>
      <div className="over">
        <ul className="navlist">
          <li className="listbord">
            <div className="mx-4 mb-2 nvlist">
              <Link to={"/category/news/"}>NEWS</Link>
            </div>
          </li>
          <li className="listbord">
            <div className="mx-4 mb-2 nvlist">
              <Link to={"/category/comparison/"}>COMPARISON</Link>
            </div>
          </li>
          <li className="listbord">
            <div className="mx-4 mb-2 nvlist">
              <Link to={"/category/phones/"}>PHONES</Link>
            </div>
          </li>
          <li className="listbord">
            <div className="mx-4 mb-2 nvlist">
              <Link to={"/category/reviews/"}>REVIEWS</Link>
            </div>
          </li>
          <li className="listbord">
            <div className="mx-4 mb-2 nvlist last-child">
              <Link to={"/category/spec/"}>SPECIFICATION</Link>
            </div>
          </li>
        </ul>
        <div className="m-3 pt-md-0 pt-5 mt-0 mt-md-5">
          <a
            href="https://facebook.com/Phonera.in"
            className="pk-social-links-link"
            target="_blank"
            rel="nofollow noopener"
            aria-label="Facebook"
          >
            <FaFacebook className="fs-3 ms-3 facebook-toggler" />
          </a>
          <a
            href="https://www.instagram.com/phonera.in"
            className="pk-social-links-link"
            target="_blank"
            rel="nofollow noopener"
            aria-label="Instagram"
          >
            <AiOutlineInstagram className="fs-3 ms-3 insta-toggler" />
          </a>
          <a
            href="https://pinterest.com/Phonerain/_saved"
            className="pk-social-links-link"
            target="_blank"
            rel="nofollow noopener"
            aria-label="Pinterest"
          >
            <ImPinterest className="fs-3 ms-3 pinterest-toggler" />
          </a>
          <a
            href="https://medium.com/@phonera"
            className="pk-social-links-link"
            target="_blank"
            rel="nofollow noopener"
            aria-label="Medium"
          >
            <FaMediumM className="fs-3 ms-3 medium-toggler" />{" "}
          </a>
        </div>
        <form className="px-4">
          <div className="formbg p-4">
            <div className="formtxt text-uppercase">Subscription Form</div>
            <input
              className="mb-2 py-2 input-box w-100 px-3"
              type="text"
              placeholder="Enter your name"
              value={userName}
              onChange={handleUser}
            />
            <input
              className="my-2 py-2 input-box w-100 px-3"
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={handleChange}
            />
            <p className="responseMsgToggler">{response}</p>
            {isLoading ? <LoadingSpinner /> : null}

            <button
              className="btn my-3 input-box subscribe-btn"
              type="button"
              onClick={() => {
                if (!check) {
                  caseErrorCheck("checkResToggler");
                  setCheckResponse(
                    "Please confirm that you agree with our policies."
                  );
                } else {
                  Useremail();
                }
              }}
            >
              SUBSCRIBE
              <span>
                <RiArrowDropRightLine className="icon" />
              </span>
            </button>
            <div>
              <input
                type="checkbox"
                className="check"
                checked={check}
                onChange={(e) => {
                  checkInput(e);
                  if (check) {
                    caseErrorCheck("checkResToggler");
                    setCheckResponse(
                      "Please confirm that you agree with our policies."
                    );
                  } else {
                    caseSuccessCheck("checkResToggler");
                    setCheckResponse("");
                  }
                }}
              />
              <p className="checkPara">
                By checking this box, you confirm that you have read and are
                agreeing to our terms of use regarding the storage of the data
                submitted through this form.
              </p>
              <p className="checkResToggler">{checkResponse}</p>
            </div>
          </div>
        </form>
        <div className="p-3">
          <div className="trending-post pt-4">TRENDING POST</div>

          <div className="py-3 toggle-image">
            {data.allStrapiPost.nodes.slice(0, 3).map((e, i) => {
              return (
                <React.Fragment key={i}>
                  <Link to={`/${e.slug}/`}>
                    <GatsbyImage
                      image={
                        e.image.localFile?.childImageSharp?.gatsbyImageData
                      }
                      style={{
                        height: "100%",
                        width: "100%",
                        minHeight: "100px",
                      }}
                      imgStyle={{ height: "100%" }}
                      loading="eager"
                      alt="img"
                      objectFit="cover"
                    />
                  </Link>
                  <div className="cardtxt my-3 pb-3">
                    <Link to={`/${e.slug}/`}
                      dangerouslySetInnerHTML={{ __html: e.Title }}
                    ></Link>
                  </div>
                  <p className="pb-4" style={{ cursor: 'auto' }}>
                    {new Date(e.date)
                      .toDateString()
                      .split(" ")
                      .slice(1, 4)
                      .toString()
                      .replace(",", " ")}
                  </p>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default React.memo(Toggler);